<template>
  <div class="app-list">
    <panel-calendar
      ref="calendarPanel"
      :isMyTask="true"
      :isAccountCenter="true"
      :isAdmin="isAdmin"
      :userIds="userIds"
      @setSearchDate="setSearchDate"
      :queryParam="queryParam"
      @getTitle="getTitle"
      homeStyle="top:130px;"
    />
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import PanelCalendar from '@/views/modules/panel-calendar.vue'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
export default {
  name: 'TaskPage',
  components: {
    ActionsTasks,
    PanelCalendar
  },
  data() {
    return {
      isAdmin: false,
      loading: false,
      userIds: [],
      todayTasks: [],
      startDate: '',
      endDate: '',
      tomorrowTasks: [],
      queryParam: {},
      nowDate:'',
      afterTomorrowTasks: [],
      mytasksDetialPath: '/translate/mytasks/detail',
      mytasksUploadPath: '/translate/mytasks/upload',
      translateMytasksDetialPath: '/translate/mytasks/index',
      translateEditorPath: '/translate/editor',
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.openDetail(record)
          }
        }
      })
    }
  },

  created() {
    const { positions, post, roles, userFile, id } = Vue.ls.get(USER_INFO) || {}
    this.isAdmin = false
    this.queryParam.userIds = id
    this.userIds = [id]
    if (roles) {
      roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    }
  },
  methods: {
    getTitle() {
      this.calendarDate = document.getElementsByClassName('fc-toolbar-title')[0].innerText || ''
      this.$nextTick(() => {
        let date = new Date()
        var Y = date.getFullYear() + ''
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + ''
        this.nowDate = Y + '年' + M + '月'
      })
    },
    setSearchDate({ startDate, endDate }) {
      if (startDate) this.startDate = startDate
      if (endDate) this.endDate = endDate
      this.queryParam.startDate = startDate?.format('YYYY-MM-DD') || ''
      this.queryParam.endDate = endDate?.format('YYYY-MM-DD') || ''
    },
    loadData() {},
    handleLinkTranslateMytasks() {
      createAlink(this.translateMytasksDetialPath, '_self')
      // this.$router.path({ path: this.translateMytasksDetialPath })
    },
    handleLinkTasks(record) {
      if (record.stageName === '给稿') {
        this.$router.push({
          path: `${this.mytasksUploadPath}/${record.taskId}/${record.productionId}/${record.chapterId}`
        })
      } else {
        this.$router.push({
          path: `${this.translateEditorPath}/${record.taskId}/${record.productionId}/${record.chapterId}/${record.platformId}`
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-list {
  .meta-cardInfo {
    zoom: 1;
    margin-top: 16px;

    > div {
      position: relative;
      text-align: left;
      float: left;
      width: 50%;

      p {
        line-height: 32px;
        font-size: 24px;
        margin: 0;

        &:first-child {
          color: rgba(0, 0, 0, 0.45);
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
