var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-list" },
    [
      _c("panel-calendar", {
        ref: "calendarPanel",
        attrs: {
          isMyTask: true,
          isAccountCenter: true,
          isAdmin: _vm.isAdmin,
          userIds: _vm.userIds,
          queryParam: _vm.queryParam,
          homeStyle: "top:130px;",
        },
        on: { setSearchDate: _vm.setSearchDate, getTitle: _vm.getTitle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }